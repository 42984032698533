<template>
  <Dialog
    v-model:visible="showEmailDialog"
    :style="{ width: '650px' }"
    header="Document Email"
    :modal="true"
    class="theme-dialog"
    maximizable
    @hide="closeEmailDialogue()"
  >
    <div class="document-email flex flex-column">
      <div class="w-12 flex flex-column">

        <!-- Using .flex with .grid is required because TailwindCSS .grid applies "display: grid" -->

        <!-- To -->
        <div class="grid flex align-items-center my-1 mx-0" :class="this.emtpyEmailToError || this.invalidEmailToError ? 'deerror' : ''">
          <label class="col-fixed label">To</label>
          <input v-if="type == 'BOL' || type == 'LTLBOL' || type == 'QUOTE' || type == 'CustomerProfile'"
            v-model="selectedEmail"
            type="text"
            class="col email-form-control border-control"
            placeholder="Enter Text"
            @blur="updateEmailList($event)"
            autofocus
          />
          <Dropdown v-else
            v-model="selectedEmail"
            :options="emaillist"
            :style="'padding:0px'"
            class="col form-control border-control documentemail-dropdown"
            placeholder="Select"
            @change="changeSelectedEmail($event)"
          />
        </div>

        <!-- CC -->
        <div class="grid flex align-items-center my-1 mx-0" :class="this.invalidEmailCCError ? 'deerror' : ''">
          <label class="col-fixed label label-align">CC</label>
          <input v-if="type === 'RCON'"
            v-model="DraftEmail.EmailCC"
            type="text"
            class="col email-form-control border-control"
            placeholder="Enter Text"
            @blur="updateCCEmailList($event)"
          />
          <input v-else
            v-model="DraftEmail.EmailCC"
            type="text"
            class="col email-form-control border-control"
            placeholder="Enter Text"
            @input="isValidEmail($event.target.value.split(','),'EmailCC')"
          />
        </div>

        <!-- BCC -->
        <div class="grid flex align-items-center my-1 mx-0" :class="this.invalidEmailBCCError ? 'deerror' : ''">
          <label class="col-fixed label">BCC</label>
          <input
            v-model="DraftEmail.EmailBCC"
            type="text"
            class="col email-form-control border-control"
            placeholder="Enter Text"
            @input="isValidEmail($event.target.value.split(','),'EmailBCC')"
          />
        </div>

        <!-- Subject -->
        <div class="grid flex align-items-center my-1 mx-0">
          <label class="col-fixed label">Subject</label>
          <input
            v-model="DraftEmail.Subject"
            type="text"
            class="col email-form-control border-control"
            placeholder="Enter Text"
          />
        </div>

        <!-- Attachments -->
        <div class="grid flex align-items-center my-1 mx-0">
          <label class="col-fixed label">Attachments</label>
          <input
            v-model="attachments"
            type="text"
            class="col email-form-control border-control"
            placeholder=""
            disabled
          />
        </div>
        <!--  /// Do not delete, /// will help if want to open attachment in email popup 

              <div class="w-12 mr-2 mt-2 flex">
                  <label class="w-2 label">Attachments</label>
                  <div class="attach-form-control mr-1 border-control flex">
                  <a type="text"
                  v-for="(documentEmail, index) in this.selectedDocuments" :key="index"
                      :value="documentEmail.OriginalFileName"
                      @click="getPdf(documentEmail.FullPath)"
                      class="email-docs mr-1 border-control"
                      placeholder=""
                  >{{documentEmail.OriginalFileName+", "}}</a>
                  </div>
              </div> -->
      </div>
      <div class="w-12 mt-3 flex-grow-1 flex flex-column">
        <p
          v-if="this.mailMethod == 'sendEmail'"
          class="p-textarea themeWisePickBC flex-grow-1"
          rows="5"
          placeholder=""
          v-html="DraftEmail.Body"
          maxlength="100"
          style="overflow: auto;"
        />
        <Editor
          v-else
          class="p-textarea themeWisePickBC flex-grow-1 flex flex-column"
          rows="5"
          placeholder=""
          v-model="DraftEmail.Body"
          maxlength="100"
          editorStyle="flex-grow: 1"
        />
      </div>      
      <hr class="mb-3 mt-1" />
    </div>
    <template #footer>
      <button class="theme-btn" @click="sendEmail()">Ok</button>
      <button class="btn-outline" @click="closeEmailDialogue()">Cancel</button>
    </template>
  </Dialog>
  <vue-element-loading
    :active="isActive"
    spinner="bar-fade-scale"
    color="#FF6700"
    :is-full-screen="true"
  />  
    <Toast position="bottom-right" group="de">
 <template #message="slotProps">
 <span :class="iconClass"></span>
 <div class="p-toast-message-text">
 <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
 <div class="p-toast-detail" v-html="slotProps.message.detail" />
 </div></template>
 </Toast> 
</template>

<script>
import VueElementLoading from "vue-element-loading";
import { dataProvider } from "@/api/dataProvider.js";
import Toast from "primevue/toast";
import Editor from 'primevue/editor';

export default {
  props:['emaillist','type'],
  data() {
    return {
        emtpyEmailToError: false,
        invalidEmailToError: false,
        invalidEmailCCError: false,
        invalidEmailBCCError: false,
        invalidEmailPresent: false,
        isActive:false,
        showEmailDialog: false,
       DraftEmail: {
        SenderAccount: "noreply@shipldi.com",
        Subject: "",
        Body: "",
        IsHtml: true,
        FromAccount: "noreply@shipldi.com",
        EmailTo: [],
        EmailCC: [],
        EmailBCC: [],
        }, 
        attachments:[],             
      selectedDocuments: [],
      mailMethod:'',
      selectedEmail:''
    };
  },
  mounted:function(){
    if(this.emaillist?.length > 0){
    var primaryEmail = this.emaillist[0]
    this.DraftEmail.EmailTo = []
    this.DraftEmail.EmailTo.push(primaryEmail)
    if (this.type !== 'BOL' && this.type !== 'LTLBOL' && this.type !== 'QUOTE') { // Don't set a default To email for these
      this.selectedEmail = primaryEmail
    }
    }else{
      this.DraftEmail.EmailTo = []
    }
  
  },
  components:{
    VueElementLoading,
    Toast,
    Editor
  },
  methods: {
    updateCCEmailList(){
      //replace spaces with semicolons
     this.DraftEmail.EmailCC = this.DraftEmail.EmailCC.replace(/\s+/g, ';');
     //replace comma with semi colons
     this.DraftEmail.EmailCC = this.DraftEmail.EmailCC.replace(/,/g, ';');
     //replace groupings of semi colons with a single example ;;;; due to spacing and extra comamas trying to account for all user mistakes here.
     this.DraftEmail.EmailCC = this.DraftEmail.EmailCC.replace(/;+/g, ';');

     
     let arr =  this.DraftEmail.EmailCC.split(';').map(item => item.trim());

   
    this.DraftEmail.EmailCC = arr

    },
    updateEmailList(){
      //replace spaces with semicolons
     this.selectedEmail = this.selectedEmail.replace(/\s+/g, ';');
     //replace comma with semi colons
     this.selectedEmail = this.selectedEmail.replace(/,/g, ';');
     //replace groupings of semi colons with a single example ;;;; due to spacing and extra comamas trying to account for all user mistakes here.
     this.selectedEmail = this.selectedEmail.replace(/;+/g, ';');

     
     let arr =  this.selectedEmail.split(';').map(item => item.trim());

   
    this.DraftEmail.EmailTo = arr

    },
    changeSelectedEmail(email){
      var primaryEmail = email.value;
    this.DraftEmail.EmailTo = []
    this.DraftEmail.EmailTo.push(primaryEmail)
  
    },
    closeEmailDialogue() {
      this.showEmailDialog = false;
      this.emtpyEmailToError = false;
      this.invalidEmailToError = false;
      this.invalidEmailCCError = false;
      this.invalidEmailBCCError = false;
      this.selectedDocuments = [];
      this.DraftEmail= {
        SenderAccount: "noreply@shipldi.com",
        Subject: "",
        Body: "",
        IsHtml: true,
        FromAccount: "noreply@shipldi.com",
        EmailTo: [],
        EmailCC: [],
        EmailBCC: [],
        } 
        this.attachments=[]               
    },
    async sendEmail() {

        if(this.DraftEmail.EmailTo.length == 0){
                  this.emtpyEmailToError = true;
                        this.$toast.add({
                        severity: "error",
                        summary: "error",
                        detail: "Please enter a email address",
                        group: 'de'
                        });    
                        return false;          
        }else{this.emtpyEmailToError = false;}

        if(this.invalidEmailToError || this.invalidEmailCCError || this.invalidEmailBCCError){
                        this.$toast.add({
                        severity: "error",
                        summary: "error",
                        detail: "Please enter a valid email address",
                        group: 'de'
                        });    
                        return false;          
        }

            if(this.mailMethod == "sendEmail"){
            this.isActive = true;

            await dataProvider.sendEmail(this.DraftEmail).then((res)=>{
                if(res)
                {
                    if(res.Success){
                        this.$toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: "Mail sent successfully.",
                        life: 3000,
                        group: 'de'
                        });
                    }
                }
            });
            this.isActive = false;
        }

        if(this.mailMethod == "sendEmailWithDocuments"){
                    this.isActive = true;
      await dataProvider.sendEmailWithDocuments(this.DraftEmail).then((res)=>{
        if(res)
        {
            if(res.Success){
                this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: "Mail sent successfully.",
                life: 3000,
                group: 'de'
                });
            }
        }
      });
                  this.isActive = false;
    }

        if(this.mailMethod == "sendEmailWithAttachments"){
            this.isActive = true;
      await dataProvider.sendEmailWithAttachments(this.DraftEmail).then((res)=>{
        if(res)
        {
            if(res.Success){
                this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: "Mail sent successfully.",
                life: 3000,
                group: 'de'
                });
            }
        }
      });
            this.isActive = false;
    }
      this.showEmailDialog = false;
      this.emtpyEmailToError = false;
      this.invalidEmailToError = false;
      this.invalidEmailCCError = false;
      this.invalidEmailBCCError = false;
      this.selectedDocuments = [];
       this.selectedEmail = this.primaryEmail;
    },
    isValidEmail(emailList,from) {

        const emailRegex = /^\s*([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\s*,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*\s*$/;

      if(typeof(this.DraftEmail.EmailTo) == "string" && from == "EmailTo"){
          this.DraftEmail.EmailTo = this.DraftEmail.EmailTo.split(",");
          this.DraftEmail.EmailTo = this.DraftEmail.EmailTo[0] == '' ? [] : this.DraftEmail.EmailTo; 
          emailList =  this.DraftEmail.EmailTo;
          this.emtpyEmailToError = emailList.length == 0 ? true : false;
          const hasInvalidEmailTo = emailList.some(email => !emailRegex.test(email));   
          this.invalidEmailToError = !hasInvalidEmailTo ? false : true      
          return hasInvalidEmailTo;   
      }

      if(typeof(this.DraftEmail.EmailBCC) == "string" && from == "EmailBCC"){
          this.DraftEmail.EmailBCC = this.DraftEmail.EmailBCC.split(",");
          this.DraftEmail.EmailBCC = this.DraftEmail.EmailBCC[0] == '' ? [] : this.DraftEmail.EmailBCC; 
          emailList =  this.DraftEmail.EmailBCC;
          const hasInvalidEmailBCC = emailList.some(email => !emailRegex.test(email));
          this.invalidEmailBCCError = !hasInvalidEmailBCC ? false : true
          return hasInvalidEmailBCC
      }

      if(typeof(this.DraftEmail.EmailCC) == "string" && from == "EmailCC"){
          this.DraftEmail.EmailCC = this.DraftEmail.EmailCC.split(",");
          this.DraftEmail.EmailCC = this.DraftEmail.EmailCC[0] == '' ? [] : this.DraftEmail.EmailCC; 
          emailList = this.DraftEmail.EmailCC 
          const hasInvalidEmailCC = emailList.some(email => !emailRegex.test(email));
          this.invalidEmailCCError = !hasInvalidEmailCC ? false : true
          return hasInvalidEmailCC
      }
    },    
    openEmailfromSourceLoad(draftEmail) {
      this.DraftEmail = draftEmail;      
      this.showEmailDialog = true;
      this.mailMethod = "sendEmail";
    },    
    openEmailfromLDIAgentDocs(draftEmail, selectedDocs, attachmentsArray) {
        console.log("From LDIAgentDocs : ", selectedDocs);
        draftEmail.EmailTo = '';
      this.DraftEmail = draftEmail;
      this.attachments = attachmentsArray;
      this.selectedDocuments = selectedDocs;
      var documents = this.selectedDocuments.map((p) => p.DocumentID);
      this.DraftEmail.Documents = documents;        
      this.showEmailDialog = true;
      this.mailMethod = "sendEmailWithDocuments";
    },
    openEmailfromCarrierDocument(draftEmail, selectedDocs, attachmentsArray) {
      this.DraftEmail = draftEmail;
      this.attachments = attachmentsArray;
      this.selectedDocuments = selectedDocs;
      var documents = this.selectedDocuments.map((p) => p.DocumentID);
      this.DraftEmail.Documents = documents;  
      console.log(this.selectedDocuments);
      this.showEmailDialog = true;
      this.mailMethod = "sendEmailWithDocuments";      
    },
    openEmailfromReportViewer(draftEmail, attachmentsArray) {
      console.log("From ReportViewer : ");
      this.DraftEmail = draftEmail;
      this.DraftEmail.EmailTo = [this.selectedEmail];
      this.attachments = attachmentsArray;      
      console.log("report byte array : ", draftEmail);
      this.showEmailDialog = true;
      this.mailMethod = "sendEmailWithAttachments";      
    },    
  },
};
</script>

<style lang="scss">
.theme-dark{
  .document-email {
  .email-form-control {
    border: 1px solid #2e2f3c;
    color: white;
    background:#2e2f3c;
    padding: 10px;
    border-radius: 1px;
    height: 30px;
    display: inline-block;
    width: 100%;
  }
  .csC8F6D76,.cs4306042E{
    color: white !important;
  }
  .documentemail-dropdown{
    background: #2e2f3c;
  }
}
}
.document-email {
  height: 100%;
 .deerror {
  .email-form-control {
    border: 1px solid #ef4d43;

    .p-button {
      border: 1px solid transparent;
      border-left: 1px solid #ef4d43;
    }
  }
} 
  .email-form-control {
    border: 1px solid #cbd2d9;
    padding: 10px;
    border-radius: 1px;
    height: 30px;
    display: inline-block;
    width: 100%;
  }
  .attach-form-control {
    border: 1px solid #cbd2d9;
    padding: 4px 4px 4px 10px;
    border-radius: 1px;
    height: 30px;
    display: inline-block;
    width: 100%;
  }
  .label {
    font-weight: 400;
    width: 12ch;
    padding: 0px;
  }
  .email-docs {
    font-weight: 500;
    color: rgb(31, 1, 255);
    cursor: pointer;
  }
  .documentemail-dropdown{
      display: inline-flex;
      .p-dropdown-label{
      font-size: 14px;
      }
      .p-dropdown-label.p-placeholder{
        color: #9ca6af;
      }
  }
}
</style>